
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import {
  createScene,
  updateScene,
  bindScene
} from '@/api/index'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { filterKeys } from '@/hooks/common-hooks'
import vueJsonEditors from 'vue-json-editor'
interface FormProps {
  scene: string;
}
export default defineComponent({
  name: 'createScene',
  props: ['id'],
  components: { vueJsonEditors },
  emits: ['changeIndex'],
  setup(props, context) {
    const router = useRouter()
    const formRef = ref()
    const init = ref({})
    const formState: FormProps = reactive({
      scene: ''
    })
    const rules = {
      scene: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入场景名称',
          whitespace: true
        }
      ]
    }
    const onJsonChange = (value: object) => {
      console.log('value1:', value)
      init.value = value
    }
    // 获取场景信息
    const getSceneInfo = () => {
      bindScene({
        scene_id: parseInt(props.id)
      }).then(res => {
        filterKeys(formState, res.data)
        init.value = res.data.mr_json ? JSON.parse(res.data.mr_json) : {}
      })
    }
    // 创建
    const fetchCreatScene = () => {
      createScene(Object.assign(formState, {
        mr_json: JSON.stringify(init.value)
      })).then(res => {
        message.success('创建成功')
        context.emit('changeIndex', 0)
      })
    }
    // 编辑
    const fetchEditScene = () => {
      updateScene(Object.assign(formState, {
        id: props.id,
        mr_json: JSON.stringify(init.value)
      })).then(res => {
        message.success('修改场景信息成功')
        router.replace({ name: 'SenceManager' })
      })
    }
    const onSubmit = (callback: () => {}) => {
      formRef.value
        .validate()
        .then(() => {
          callback()
        })
        .catch((error: ValidateErrorEntity<FormProps>) => {
          console.log('error', error)
        })
    }
    onMounted(() => {
      if (props.id) {
        getSceneInfo()
      }
    })
    return {
      formRef,
      formState,
      rules,
      onSubmit,
      fetchCreatScene,
      fetchEditScene,
      onJsonChange,
      init
    }
  }
})
